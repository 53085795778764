import React from 'react'
import tw, { styled } from 'twin.macro'
import Navbar from '../../components/elements/Navbar'
import { Subtitle } from '../datenschutz'
import components from '../../content/common'
import Footer from '../../components/elements/Footer'
import pressePreviewImage from '@images/presse/festl-bad-reichenhall-preview.jpg'
import presseImage1 from '@images/presse/Festl_Tour_Impressionen_1.jpg'
import presseImage3 from '@images/presse/Festl_Tour_Impressionen_3.jpg'

const Section = styled.section`
  ${tw`w-full bg-radler pb-20 pt-20 lg:pb-40 lg:pt-56 overflow-hidden`}

  p {
    ${tw`text-white pt-5 text-15px lg:text-18px font-roboto`}
    b {
      ${tw``}
    }
  }

  a.text-green {
    ${tw`text-gelb`}
    &:hover {
      filter: drop-shadow(1px 2px 0.75rem rgba(247, 211, 3, 1));
    }
  }
`

const ContentWrap = tw.div`max-w-master mx-auto`
const ContentRow = tw.div`grid grid-cols-12 gap-4 mt-4`
const ContentImageWrapper = tw.div`flex justify-center mt-8`
const ContentText = tw.div`col-span-12 xs:col-span-8`
const ImageSource = tw.small`text-white`
const ContentRowImage = tw.div`col-span-12 xs:col-span-6`

const PressePage = () => {
  const presseImage = '/download/festl-bad-reichenhall.jpg';

  return (
    <>
      <Navbar content={components.navbar} />
        <Section>
          <ContentWrap>
            <div tw="max-w-screen-2xl mx-auto px-3 sm:px-7">
              <Subtitle tw="max-w-full">
                Bayerisch feiern mit Live-Musik!
              </Subtitle>
              <ContentText>
                <p>
                  <strong>
                    Die Alpin FM Festl Tour mit ERDINGER Brauhaus macht Halt in Bad Reichenhall
                  </strong>
                </p>
                <p>
                  Erding, Juni 2024<br />
                  Die Alpin FM Festl Tour mit ERDINGER Brauhaus geht in die nächste Runde. Nach einer spektakulären
                  Tournee im Sommer 2023 zieht die Festivalreihe erneut durch Oberbayern. Vier Sonntage, vier Orte und
                  vier unvergessliche Feste stehen auf dem Programm – den Anfang macht Bad Reichenhall.
                </p>
                <p>
                  Auftakt ist am Sonntag, den 30. Juni, auf dem Florianiplatz, mitten in der historischen Altstadt von
                  Bad Reichenhall. Ab 14 Uhr verwandelt sich der malerisch gelegene Platz in eine Festhochburg. Für
                  ordentliche Stimmung und eine mitreißende Atmosphäre sorgen in diesem Jahr die Live-Auftritte der
                  bayerischen Bands Kopfeck, Luegstoa C, Gsindl und Sauerkirsch. Der Eintritt ist frei und um das
                  leibliche Wohl kümmern sich das ERDINGER Brauhaus-Team mit der Unterstützung des Vereins
                  „FürReichenhall e.V.“. Damit es an nichts fehlt, werden die Gäste sowohl mit ERDINGER Brauhaus
                  Spezialitäten und alkoholfreien Getränken als auch deftigen Grillspezialitäten versorgt. Dank der
                  großzügigen Unterstützung des Stadtmarketings Bad Reichenhall kann die Alpin FM Festl Tour richtig
                  durchstarten. Alpin FM, die Bands sowie der lokale Verein „FürReichenhall e.V. und ERDINGER
                  Brauhaus heißen jeden herzlich willkommen, mitzufeiern und die einzigartige Atmosphäre zu erleben!
                </p>
                <p>
                  Homepage: <a href="https://www.erdinger-brauhaus.de/events/" target="_blank">www.erdinger-brauhaus.de/events/</a><br />
                  Instagram: <a href="https://www.instagram.com/erdinger.brauhaus/" target="_blank">@erdinger.brauhaus</a>
                </p>
              </ContentText>
              <ContentImageWrapper>
                <div>
                  <a 
                      href={presseImage} 
                      target="_blank"
                    >
                      <img src={pressePreviewImage} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus" />
                  </a>
                  <ImageSource>Bildquelle: Darius Kappes / ERDINGER Weißbräu.</ImageSource>
                </div>
              </ContentImageWrapper>

              <br />
              <ContentRow>
                <ContentRowImage>
                  <img src={presseImage1} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                  <ImageSource>Bildquelle: Darius Kappes / ERDINGER Weißbräu.</ImageSource>
                </ContentRowImage>
                <ContentRowImage>
                  <img src={presseImage3} alt="Alpin FM Festl Tour mit ERDINGER Brauhaus Impressionen" />
                  <ImageSource>Bildquelle: Marcel Laponder / ERDINGER Weißbräu</ImageSource>
                </ContentRowImage>
              </ContentRow>
              <p tw="text-center">Eindrücke der legendären letztjährigen Alpin FM Festl Tour mit ERDINGER Brauhaus</p>
            </div>
          </ContentWrap>
        </Section>
      <Footer stickyVisible={false} />
    </>
  )
}

export default PressePage
